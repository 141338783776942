import Client from "./client";
import GetStorage from "./browserStorage";
import { userAndSession, baseData } from "./eventPreparation";
import { EventI } from "./interfaces";

interface EventPayload {
    evt: string,
    str?: string,
    int?: number,
    topics?: string[]
}

class arrigoo {
    public storage: Storage;
    public cdpClient: Client;

    constructor(cdpHost: string, apiKey: string) {
        this.storage = GetStorage(localStorage);
        this.cdpClient = Client.getInstance(cdpHost, apiKey, () => this.logOut());
    }

    public logOut() {
        this.storage.cid = "";
        this.storage.refresh = null;
        this.storage.token = null;
    }

    public sendEvent(e: EventPayload) {
        const event = userAndSession(this.storage);
        const baseInfo = baseData(e.evt, e.str, e.int);
        const base = { ...event, ...baseInfo };
        if (e.topics) base.topics = e.topics;

        // Trigger eventListeners
        this.cdpClient.saveEvent(base as EventI, (response: any) => {
            const data = response;
            if (data.cid) this.storage.cid = data.cid;
            const bEvent = new CustomEvent('agoo', { detail: data });
            document.dispatchEvent(bEvent);
        });
    }

    getHashValues() {
        return window.location.hash;
    }

    getSearchValue(param: string) {
        return new URLSearchParams(window.location.search || '').get(param);
    }

    public static init() {
        const w = (window as any)
        const arrigoo = w.agoo = new this('http://localhost:3030', 'supersecret');
        document.addEventListener('agoo', (evt) => {
            // const debug2 = document.getElementById("debug2");
            // if (!debug2) return;
            // debug2.innerText = JSON.stringify((evt as any).detail);
        });
        // arrigoo.sendEvent('pageview', 'Home page', arrigoo.storage.pv);
        return arrigoo;
    }

}

export default arrigoo;
