import { FC, useState, useRef } from "react";
import { redirect } from "react-router-dom";
import CdpClient from "@/client";
import logo from '@/logo.png';

interface LoginPageProps {
    cdpClient: CdpClient;
    authenticated: (token: string, refreshToken: string, role: string) => void;
}

export const Login: FC<LoginPageProps> = ({ cdpClient, authenticated }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [resetToken, setResetToken] = useState('');
    const [showReset, setShowReset] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [auth2fa, setAuth2fa] = useState(false);
    const [auth2faToken, setAuth2faToken] = useState('');
    
    return (
        <div data-theme="dark">
            <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item" href="/admin">
                        <img src={logo} alt="Arrigoo" />
                    </a>
                </div>
            </nav>
            <div className="section">
                <div className="container">
                    
                    <div className="box">
                    <h1 className="title">Login</h1>
                    {auth2fa &&
                        <div>
                            <div className="field">
                                <label className="label">2FA token</label>
                                <div className="control">
                                    <input className="input" autoFocus placeholder="2FA token" size={10} type="text" onChange={(evt) => setAuth2faToken(evt.target.value)} />
                                </div>
                            </div>
                            <div className="buttons">
                                <button className="button is-primary" onClick={(evt) => {
                                        cdpClient.login2fa(auth2faToken).then((response: any) => {
                                            console.log(response);
                                            if (response.data.token && response.status === 200) {
                                                authenticated(response.data.token, response.data.refreshToken, response.data.role);
                                            }
                                        }).catch((error: any) => {
                                            console.log('error', error);
                                        });
                                    }}
                                >Login</button>
                            </div>
                        </div>
                    }
                    {!auth2fa &&
                        <>
                            <div className="field">
                                <label className="label">Email</label>
                                <div className="control">
                                    <input className="input" autoFocus placeholder="Email address" size={10} type="email" onChange={(evt) => setEmail(evt.target.value)} />
                                </div>
                            </div>
                            <div className="field">
                                <label className="label">Password</label>
                                <div className="control">
                                    <input className="input" placeholder="Password" size={10} type="password" onChange={(evt) => setPassword(evt.target.value)} />
                                </div>
                            </div>
                            <div className="field">
                                <div className="control">
                                    <button className="button is-primary" onClick={(evt) => {
                                        evt.preventDefault();
                                        cdpClient.login(email, password).then((response: any) => {
                                            console.log(response);
                                            if (response.status === 204) {
                                                setAuth2fa(true);
                                                return;
                                            }

                                            if (response.data.token) {
                                                authenticated(response.data.token, response.data.refreshToken, response.data.role);
                                            }
                                        }).catch((error) => {
                                            console.log('error', error);
                                        });
                                    }}>Login</button>
                                </div>
                            </div>

                            <div className="field">
                                <button onClick={() => setModalActive(true)} >Reset password</button>
                            </div>
                        </>
                    }
                    <div className={"modal" + (modalActive ? " is-active" : "")}>
                        <div className="modal-background"></div>
                        <div className="modal-content">
                            <div className="box">
                                <h2 className="subtitle">Password reset</h2>
                                <div>
                                    <div className="field">
                                        <label className="label">Email</label>
                                        <div className="control">
                                            <input className="input" defaultValue={email} type="email" onChange={(evt) => setEmail(evt.target.value)} />
                                        </div>
                                    </div>
                                    {!showReset ?
                                        <div className="field">
                                            <div className="control">
                                                <button className="button is-primary" onClick={() => {
                                                    cdpClient.requestPassword(email).then((response: any) => {
                                                        setShowReset(true);
                                                    }).catch((error) => {
                                                        console.log('error', error);
                                                    });

                                                }}>Send reset token</button>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="field">
                                                <label className="label">Password</label>
                                                <div className="control">
                                                    <input className="input" type="password" onChange={(evt) => setPassword(evt.target.value)} />
                                                    <p className="help">We only have one field. We know that you will most likely paste it in the second field anyway.</p>
                                                </div>
                                            </div>
                                            <div className="field">
                                                <label className="label">Token from email</label>
                                                <div className="control">
                                                    <input className="input" type="input" onChange={(evt) => setResetToken(evt.target.value)} />
                                                    <p className="help">Copy the token you received in your mail.</p>
                                                </div>
                                            </div>
                                            <div className="buttons">
                                                <button className="button is-primary" onClick={() => {
                                                    cdpClient.resetPassword(email, resetToken, password).then((response: any) => {
                                                        console.log(response);
                                                        setModalActive(false);
                                                    }).catch((error) => {
                                                        console.log('error', error);
                                                    });
                                                }}>Reset password</button>
                                                <button className="button is-danger" onClick={() => setShowReset(false)}>Cancel</button>
                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                        <button className="modal-close is-large" aria-label="close" onClick={() => setModalActive(false)}></button>
                    </div>
                    </div>
                </div>
            </div >
        </div >
    );
};
