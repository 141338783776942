import { FC } from 'react';
import { Date } from './Date';
import { IconSearch, IconUser } from './Icons';

interface ProfileListItemProps {
    index: number;
    profile: any;
    onShowDetails: (key: number) => void;
    showDetails: boolean;
}

export const ProfileListItem: FC<ProfileListItemProps> = ({ index, profile, onShowDetails, showDetails}) => {
    return (
        <>
        <tr onClick={() => onShowDetails(index)}>
            <td>
                <IconUser />
            </td>
            <td>
                {(profile as any).i?.map((ident: any, idx: number) => {
                    return <div><span key={idx}>{ident.id_type}: {ident.id_value}</span></div>
              })}
            </td>
            <td>
                {(profile as any).s?.map((seg: any, idx: number) => {
                    return <span><span key={idx} className='tag is-warning'>{seg}</span> &nbsp;</span>
                })}
            </td>
            <td>
                <Date date={profile.ctime} format="short" />
            </td>
            <td>
                <Date date={profile.mtime} format="short" />
            </td>
            <td>
                <button className='button has-text-primary' onClick={() => onShowDetails(index)}><IconSearch /></button>
            </td>
        </tr>
            {showDetails &&
                <tr>
                    <td colSpan={6}>
                        <pre>{JSON.stringify(profile, null,2)}</pre>
                    </td>
                </tr>
            }
        </>
    );
}