import { FC, useRef, useEffect, useState } from 'react';
import { Account } from '@/interfaces';
import arrigoo from '@/arrigoo';
import { AccountContactForm, AccountTabs } from '@/forms/AccountForm';

interface DashboardProps {
    argoo: any;
}

export const AccountSettingsPage: FC<DashboardProps> = (props: DashboardProps) => {
    const argoo = props.argoo as arrigoo;
    const once = useRef(false);
    const [account, setAccount] = useState<Account | undefined>(undefined);
    useEffect(() => {
        if (!once.current) {
            argoo.cdpClient.getAccount((response: any) => {
                console.log('response', response);
                setAccount(response);
                console.log('account', account);
            });
            once.current = true;
        }
    });

    return (
        <div>  
            <AccountTabs />
            <h1 className='subtitle'>Contact settings</h1>
            {account &&
                <AccountContactForm
                    account={account as Account}
                    onSave={(acc: Account) => {
                        setAccount(acc);
                        argoo.cdpClient.updateAccount(acc, (response: any) => {
                            setAccount(response);
                        });
                    }}
                />}
        </div>
    )
};
