import { FC, useState } from "react";
import { Secret } from "@/interfaces";

interface SecretFormProps {
    secret: Secret;
    onSave: (secret: Secret) => void;
}

export const SecretForm: FC<SecretFormProps> = ({ secret, onSave }) => {
    const [sec, setSecret] = useState<Secret>(secret);
    return (
        <div>
            <div className='field'>
                <label className='label'>Secret Key</label>
                <input
                    className='input'
                    type='text'
                    defaultValue={sec.secret_key}
                    onChange={(e) => setSecret({ ...sec, secret_key: e.target.value } as Secret)}
                />
            </div>
            <div className='field'>
                <label className='label'>Secret Value</label>
                <input
                    className='input'
                    type='text'
                    defaultValue={sec.secret_value}
                    onChange={(e) => setSecret({ ...sec, secret_value: e.target.value } as Secret)}
                />
            </div>
            <div className='field'>
                <label className='label'>Description</label>
                <input
                    className='input'
                    type='text'
                    defaultValue={sec.description}
                    onChange={(e) => setSecret({ ...sec, description: e.target.value } as Secret)}
                />
            </div>
            <button
                className='button is-primary'
                onClick={() => {
                    onSave(sec);
                }}
            >Save</button>
        </div>
    );
}