import axios, { AxiosRequestConfig } from "axios";
import { EventI, Action, ActionTrigger } from "@/interfaces";

export default class Client {
    private static instance: Client;
    private apiKey: string;
    private host: string = "http://localhost:3000";
    private refreshInterval: any = null;
    private refreshToken: string = '';
    private onError: Function = () => {};
    private constructor(host: string, apiKey: string, onError?: Function) {
        this.onError = onError || this.onError;
        this.apiKey = apiKey;
        this.host = host;

    }
    public setRefreshToken(refreshToken: string) {
        this.refreshToken = refreshToken;
    }
    
    public setApiKey(apiKey: string) {
        this.apiKey = apiKey;
    }

    public static getInstance(host: string, apiKey: string, onError?: Function) {
        if (!Client.instance) {
            Client.instance = new Client(host, apiKey, onError);
        }
        return Client.instance;
    }

    public async login(email: string, password: string) {
        const post = axios.postForm(
            `${this.host}/v1/security/login`,
            { email: btoa(email), password: btoa(password) }
        );
        return post;
    }
    public async login2fa(auth2fa: string) {
        const post = axios.postForm(
            `${this.host}/v1/security/login/2fa`,
            { auth2fa: auth2fa }
        );
        return post;
    }

    public async refresh(refreshToken: string, callback?: Function, errorCallback?: Function) {
        this.refreshToken = refreshToken;
        clearInterval(this.refreshInterval);

        axios.post(
            `${this.host}/v1/security/refresh`, null, {
                headers: {'X-REFRESH-TOKEN': refreshToken, 'Accept': 'application/json'}
            }).then((response) => {
                this.apiKey = response.data.token;
                this.refreshInterval = setTimeout(() => {
                    console.log('refreshing API key')
                    this.refresh(refreshToken, callback, errorCallback);
                }, response.data.expire * 1000);
                callback && callback(response.data);
            }).catch((error) => {
                console.error('error', error);
                errorCallback && errorCallback(error);
            });
    }

    public async saveEvent(event: EventI, callback?: Function) {
        return this.request('post',`${this.host}/v1/event`, callback, event);
    }

    public async getUserData(cid: string, callback?: Function) {
        return this.request('get',`${this.host}/v1/customer/${cid}`, callback);
    }
    public async deleteCustomer(cid: string, callback?: Function) {
        return this.request('delete',`${this.host}/v1/customer/${cid}`, callback);
    }

    public async lookupCustomer(idType: string, idValue: string, callback?: Function) {
        return this.request(
            'post',
            `${this.host}/v1/customer/${idType}`,
            callback, 
            {id_value: idValue}
        );
    }

    public async getCustomers(callback?: Function) {
        return this.request('get',`${this.host}/v1/customer`, callback);
    }

    //// ------- Properties --------- ////
    public async getProperties(callback?: Function) {
        return this.request('get',`${this.host}/v1/property`, callback);
    }

    // Save a property
    public async saveProperty(property: any, callback?: Function) {
        if (!property.id) {
            return this.request('post',`${this.host}/v1/property`, callback, property);
        }
        return this.request('put',`${this.host}/v1/property/${property.id}`, callback, property);
    }
    public async saveNewProperty(property: any, callback?: Function) {
        return this.request('post',`${this.host}/v1/property`, callback, property);
    }

    public async deleteProperty(property: any, callback?: Function) {
        return this.request('delete',`${this.host}/v1/property/${property.id}`, callback, property);
    }

    public async getEventSpecs(callback?: Function) {
        return this.request('get',`${this.host}/v1/event-spec`, callback)
    }

    // Save event spec
    public async saveEventSpec(eventSpec: any, callback?: Function) {
        return this.request('put',`${this.host}/v1/event-spec/${eventSpec.oldEvt}`, callback, eventSpec);
    }

    // Save new event spec
    public async saveNewEventSpec(eventSpec: any, callback?: Function) {
        return this.request('post',`${this.host}/v1/event-spec`, callback, eventSpec);
    }

    public async getEvents(callback?: Function, type: string = '') {
        const rData: any = {};
        if (type) {
            rData.evt = type;
        }
        return this.request('get',`${this.host}/v1/event-api`, callback, rData);
    }
    
    // Get events for customer
    public async getEventsForCustomer(cid: string, type: string, callback?: Function) {
        const rData: any = {};
        if (type) {
            rData.evt = type;
        }
        return this.request('get',`${this.host}/v1/event-api/cid/${cid}`, callback, rData);
    }

    public async deleteEvent(id: number, callback?: Function) {
        return this.request('delete',`${this.host}/v1/event-api/${id}`, callback);
    }

    // Get segments
    public async getSegments(callback?: Function) {
        return this.request('get',`${this.host}/v1/segment`, callback);
    }

    // Save segment
    public async saveSegment(segment: any, callback?: Function) {
        return this.request('put',`${this.host}/v1/segment`, callback, segment);
    }

    // Save new segment
    public async createSegment(segment: any, callback?: Function) {
        return this.request('post', `${this.host}/v1/segment`, callback, segment);
    }

    // Delete segment
    public async deleteSegment(id: number, callback?: Function) {
        return this.request('delete',`${this.host}/v1/segment/${id}`, callback);
    }

    // Methods for creating, updating and reading secrets
    public async getSecrets(callback?: Function) {
        return this.request('get',`${this.host}/v1/secret`, callback);
    }

    // Save secret
    public async saveSecret(secret: any, callback?: Function) {
        return this.request('put',`${this.host}/v1/secret`, callback, secret);
    }

    // Save new secret
    public async createSecret(secret: any, callback?: Function) {
        return this.request('post',`${this.host}/v1/secret`, callback, secret);
    }

    // Delete secret
    public async deleteSecret(id: number, callback?: Function) {
        return this.request('delete',`${this.host}/v1/secret/${id}`, callback);
    }

    // Get All users
    public async getUsers(callback?: Function) {
        return this.request('get',`${this.host}/v1/user`, callback);
    }

    // Save user
    public async saveUser(user: any, callback?: Function) {
        return this.request('put',`${this.host}/v1/user`, callback, user);
    }

    // Save new user
    public async createUser(user: any, callback?: Function) {
        return this.request('post',`${this.host}/v1/user`, callback, user);
    }

    // Request forgotten password token.
    public async requestPassword(email: string, callback?: Function) {
        return this.request('post',`${this.host}/v1/security/forgot-password`, callback, { email: btoa(email) });
    }

    public async getActions(callback?: Function) {
        return this.request('get',`${this.host}/v1/action`, (response: any) => { 
            const actions: Record<number, Action> = {};
            response.forEach((action: any) => {
                const newTrigger: ActionTrigger = {
                    id: action.trigger_id,
                    trigger_type: action.trigger_type,
                    trigger_type_id: action.trigger_type_id,
                    conditions: action.conditions,
                };
                if (actions[action.aid]) {
                    if (!actions[action.aid].triggers) {
                        actions[action.aid].triggers = [];
                    }
                    actions[action.aid].triggers?.push(newTrigger);
                    return;
                }
                const newAction: Action = {
                    aid: action.aid,
                    action_type: action.action_type,
                    description: action.description,
                    specs: action.specs,
                    triggers: [newTrigger]
                };
                actions[action.aid] = newAction;
            });
            callback && callback(Object.values(actions));
        });
    }
    
    // Delete an action.
    public async deleteAction(id: number, callback?: Function) {
        return this.request('delete',`${this.host}/v1/action/${id}`, callback);
    }

    // Save action
    public async saveAction(action: Action, callback?: Function) {
        return this.request('put',`${this.host}/v1/action`, callback, action);
    }
    
    //  Save new action
    public async createAction(action: Action, callback?: Function) {
        return this.request('post',`${this.host}/v1/action`, callback, action);
    }
    
    //  Save new action trigger
    public async createActionTrigger(trigger: ActionTrigger, callback?: Function) {
        const action = trigger.action;
        const payload = {
            aid: action?.aid || 0,
            ...trigger
        }
        return this.request('post',`${this.host}/v1/actiontrigger`, callback, payload);
    }
    
    // Delete an action.
    public async deleteActionTrigger(id: number, callback?: Function) {
        return this.request('delete',`${this.host}/v1/actiontrigger/${id}`, callback);
    }

    // Save action
    public async saveActionTrigger(trigger: ActionTrigger, callback?: Function) {
        const action = trigger.action;
        const payload = {
            aid: action?.aid || 0,
            ...trigger
        }
        return this.request('put',`${this.host}/v1/actiontrigger`, callback, payload);
    }
    
    // Get account details
    public async getAccount(callback?: Function) {
        return this.request('get',`${this.host}/v1/account`, callback);
    }

    // Save account details
    public async updateAccount(account: any, callback?: Function) {
        return this.request('put',`${this.host}/v1/account`, callback, account);
    }

    // Reset password
    public async resetPassword(email: string, token: string, password: string, callback?: Function) {
        return this.request('post',`${this.host}/v1/security/reset-password`, callback, { email: btoa(email), token: btoa(token), password: btoa(password) });
    }

    // Get request to stat endpoints
    public async getStats(path: string, params?: Record<string, string>,  callback?: Function) {
        const options: AxiosRequestConfig = {
            url: `${this.host}/v1/stat/${path}`,
            method: 'get',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-API-KEY': this.apiKey,
            },
        };
        if (params) {
            options.params = params;
        }
        return axios.request(
            options
        ).then((response) => callback && callback(response.data)
        ).catch((error) => {
            console.log(error)
            
            console.error('error - log out', error);
            window.location.href = '/admin';
            this.onError();
        });
     
    }

    private async request(method: string, url: string, callback?: Function, data?: any, headers?: Record<string, string>) {
        const options: AxiosRequestConfig = {
            url: url,
            method: method,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'X-API-KEY': this.apiKey,
            },
        };
        if (method === 'post' || method === 'put') {
            options.data = data;
        } else if (method === 'get') {
            options.params = data;
        }

        return axios.request(options).then((response) => {
            callback && callback(response.data);
        }).catch((error) => {
            if (error.response && error.response.status === 401) {
                console.error('error - log out', error);
                this.onError();
                window.location.href = '/admin';
                return;
            }
            if (error.code === "ERR_NETWORK") {
                this.refresh(this.refreshToken, () => {
                    this.request(method, url, callback, data, headers);
                }, (error: Error) => {
                    this.onError();
                    console.error('error', error);
                    window.location.href = '/admin';
                })
            }
        });
    }
}
