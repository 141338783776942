import { FC } from "react";

export type CheckboxItem = {
    label: string;
    value: string;
    isSelected: boolean;
}

interface CheckboxItemsProps {
    items: CheckboxItem[];
    onChange?: (items: CheckboxItem[]) => void;
}

const Checkboxes: FC<CheckboxItemsProps> = ({
    ...props
}: CheckboxItemsProps) => {

    return (
        <ul className={`mdc-list customer-properties`}>
            {props.items.map((item: CheckboxItem, idx: number) => {
                return (
                    <li key={idx} className={`mdc-list-item`}>
                        <span className="mdc-list-item__text" >
                            <label className="checkbox">
                                <input type="checkbox" value={item.value} defaultChecked={item.isSelected} onChange={(target) => {
                                    props.items[idx].isSelected = !item.isSelected;
                                    if (props.onChange) {
                                        props.onChange(props.items);
                                    }
                                }} />&nbsp;
                                <strong>{item.label}</strong>
                            </label>
                        </span>
                    </li>
                );
            })}
        </ul>
    );
};

export { Checkboxes };
