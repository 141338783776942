import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Property, SegmentSpec, Action } from '@/interfaces';
import { SegmentSpecForm } from '@/forms/SegmentSpecForm';

interface SegmentsSpecPageProps {
    cdpClient: any;
}

export const SegmentsSpecFormPage: React.FC<SegmentsSpecPageProps> = ({ cdpClient }) => {
    const { sid } = useParams<{ sid: string }>();
    const once = useRef(false);
    const [segments, setSegments] = useState<SegmentSpec[]>([]);
    const [propertyList, setPropertyList] = useState<Property[]>([]);
    const [actions, setActions] = useState<Action[] | null>(null)
    const navigate = useNavigate();
    const emptySegment: SegmentSpec = {
        title: '',
        sys_title: '',
        properties: [],
        conditions: [],
        webhooks: [],
        webhooks_leave: []
    };
    useEffect(() => {
        if (once.current) {
            return;
        }
        cdpClient.getSegments((response: any) => {
            setSegments(response || []);
        });
        cdpClient.getProperties((response: any) => {
            setPropertyList(response || []);
        });
        cdpClient.getActions((response: any) => {
            console.log(response)
            setActions(response);
        });
        once.current = true;
    });

    return (
        <div className="container has-text-left">
            <Link to='/admin/segment-specs'>Back to segments page</Link>
            <div className="box">
                {segments.map((segment: SegmentSpec, idx: number) => {
                    if (segment.id !== parseInt(sid + '')) return null;
                    return (
                        <div key={idx}>
                            <h2 className="title">Edit segment {segment.title}</h2> 
                            
                            <SegmentSpecForm actions={actions} segment={segment} cdpClient={cdpClient} properties={propertyList} postSave={() => navigate('/admin/segment-specs')} />
                            
                        </div>
                    )
                })}
                {
                    sid === 'new' && (
                        <div>
                            
                            <h2 className="card-header-title">New segment</h2>
                            
                            
                            <SegmentSpecForm actions={actions} segment={emptySegment} cdpClient={cdpClient} properties={propertyList} postSave={() => navigate('/admin/segment-specs')} />
                            
                        </div>
                    )
                }
            </div>
        </div>
    )
}
