import { ButtonHTMLAttributes, FC, MouseEventHandler, ReactNode } from "react";
import '@material/button/dist/mdc.button.min.css'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children?: ReactNode;
    className?: string;
    disabled?: boolean;
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    text?: string;
    variant?: "filled" | "tonal" | "outlined" | "elevated" | "text";
}

const Button: FC<ButtonProps> = ({
    children,
    className,
    disabled,
    variant = "filled",
    onClick,
    text = "Button",
    ...props
}: ButtonProps) => {
    return (
        <button
            className={`button ${variant} ${className || ""}`}
            disabled={disabled}
            onClick={onClick}
            {...props}>
            {children || text}
        </button>
    );
};

export { Button };
