import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { FC } from 'react';
import { ChartColors } from '@/config/Charts';


type BarChartProps = {
    data: any[];
    bars: string[];
    stackId?: string;
}

const BChart: FC<BarChartProps> = ({ data, bars, stackId }) => {
    return (
        <ResponsiveContainer height={300} width="100%">
            <BarChart width={600} height={300} data={data}>
                <CartesianGrid stroke="#ccc" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                {bars.map((bar: string, idx: number) => {
                    return (<Bar key={idx} dataKey={bar} stackId={stackId} barSize={30} fill={ChartColors[idx]} />)
                })}
            </BarChart>
        </ResponsiveContainer>
    );
}

export default BChart;
