import { useEffect, useState, useRef } from 'react';
import { SegmentSpec } from '@/interfaces';

import { Link } from 'react-router-dom';
import { IconPencil, IconPlus, IconTrash } from '@/components/Icons';

interface SegmentsSpecPageProps {
    cdpClient: any;
}

export const SegmentsSpecPage: React.FC<SegmentsSpecPageProps> = ({ cdpClient }) => {
    const once = useRef(false);
    const [segments, setSegments] = useState<SegmentSpec[]>([]);
    const [stats, setStats] = useState<any[]>([]);
    useEffect(() => {
        if (once.current) {
            return;
        }
        cdpClient.getSegments((response: any) => {
            setSegments(response || []);
        });
        cdpClient.getStats('segment', {}, (response: any) => {
            console.log('segment stats', response);
            setStats(response.days || []);
        });
        once.current = true;
    });

    return (
        <div className='container'>
            <h2 className="title">Segments {<Link to={'/admin/segment-specs/new'}><IconPlus /></Link>}</h2>
            <div className='box'>
                <table className='table is-fullwidth is-striped'>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Webhooks</th>
                            <th>Members</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {segments.map((segment: SegmentSpec, idx: number) => {
                            let count = 0;
                            stats.forEach((stat: any) => {
                                if (stat.day === segment.title) {
                                    count = stat.total;
                                }
                            });
                            return (
                                <tr key={idx}>
                                    <td><Link to={`/admin/segment/${segment.id}`}>{segment.title}</Link></td>
                                    <td>{segment.description + ""}</td>
                                    <td>{(segment.webhooks?.length + segment.webhooks_leave?.length) || "-"}</td>
                                    <td>{count}</td>
                                    <td><div className='buttons'>
                                        <Link to={`/admin/segment-specs/${segment.id}`}><span className="button"><IconPencil /></span></Link>
                                        <Link to={`/admin/segment-specs/${segment.id}`}><span className="button has-text-danger"><IconTrash /></span></Link>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className='column'>
                <div className="container has-text-left">
                    <div className="card">
                        <div className="card-header">
                            <h2 className="card-header-title">Configure segments</h2>
                        </div>
                        <div className="card-content">
                            <p>Create segments to group your customers</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
