import { FC, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Secret } from "@/interfaces";
import PageProps from "@/pages/PageProps";
import { IconActionEmail, IconActionWebhook, IconClose, IconPencil, IconPlus, IconTrash } from "@/components/Icons";
import { SecretForm } from "@/forms/SecretForm";
import { AccountTabs } from "@/forms/AccountForm";



export const SecretsPage: FC<PageProps> = ({ client }) => {
    const [secrets, setSecrets] = useState<Secret[]>([]);
    const [selectedSecret, setSelectedSecret] = useState<number>(-1);
    const once = useRef(false);

    useEffect(() => {
        if (!once.current) {
            client.getSecrets((response: any) => {
                console.log('secrets', response);
                setSecrets(response || []);
            });
            once.current = true;
        }
    }, [client]);

    return (<>
        <AccountTabs />
        <h1 className="title">Secrets <button onClick={() => setSelectedSecret(-2)}><IconPlus /></button></h1>
        <p>Secrets are for values like API keys or other variables, you need in actions, but should be kept private. Once submitted, a secret cannot be retrieved and is only used serverside.</p>
        <div className="box">
            {selectedSecret === -2 && (
                <SecretForm
                    secret={{ secret_key: "", description: "" }}
                    onSave={(secret) => {
                        client.createSecret(secret);
                        secret.secret_value = "";
                        setSecrets([...secrets, secret]);
                        setSelectedSecret(-1);
                    }}
                />
            )}
            {secrets.map((secret: Secret, idx: number) => {
                return (<div key={idx} className="box">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                {secret.secret_key === "webhook" ? <IconActionWebhook /> : <IconActionEmail />}
                            </div>
                            <div className="level-item">
                                <h3 className="subtitle">
                                    {secret.description}
                                </h3>
                            </div>
                            <div className="level-item">
                                <span className="tag">{secret.secret_key}</span>
                            </div>
                            <div className="level-item">
                                <span className="is-small">{secret.utime}</span>
                            </div>
                        </div>
                        <div className="buttons">
                        <button className="button has-text-primary" onClick={() => setSelectedSecret(idx)}> <IconPencil /></button>
                        <button className="button has-text-danger" onClick={() => {
                                client.deleteSecret(secret.sid || 0);
                                setSecrets(secrets.filter((s, i) => i !== idx));
                                setSelectedSecret(-1);
                            }}><IconTrash /></button>
                        </div>
                    </div>
                    {selectedSecret === idx && (
                        <div>
                            <hr />
                            <div className="has-text-right">
                                <button className="button" onClick={() => {
                                    setSelectedSecret(-1);
                                }}><IconClose /></button>
                            </div>
                            <SecretForm
                                secret={secret}
                                onSave={(secret) => {
                                    client.saveSecret(secret);
                                    secret.secret_value = "";
                                    setSecrets(secrets.map((s, i) => i === idx ? secret : s));
                                    setSelectedSecret(-1);
                                }}
                            />
                        </div>
                    )}
                </div>)
            })
            }
        </div>
    </>)
}