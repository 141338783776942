import { useEffect, useState, useRef, FC } from 'react';
import { User } from '@/interfaces';
import { AccountTabs } from '@/forms/AccountForm';
import { UserForm } from '@/forms/UserForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { IconTrash, IconPencil } from '@/components/Icons';

interface UserPageProps {
    cdpClient: any;
}

export const UserPage: FC<UserPageProps> = (props: UserPageProps) => {
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<number>(-1);
    const once = useRef(false);
    // Fetch users from the API once
    useEffect(() => {
        if (!once.current) {
            props.cdpClient.getUsers((response: any) => {
                console.log('users', response)
                setUsers(response || []);
                once.current = true;
            });
        }
    });
    return (
        <div className="container">
            <AccountTabs />
            <h2 className="subtitle">Users <a onClick={() => setSelectedUser(-2)}><FontAwesomeIcon icon={faPlusCircle} /></a></h2>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>Full name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Role</th>
                        <th>Created</th>
                        <th>Updated</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedUser === -2 &&
                        <UserForm
                            onSave={(user: User) => {
                                props.cdpClient.createUser(user, (response: any) => {
                                    props.cdpClient.getUsers((response: any) => {
                                        setUsers(response.data || []);
                                        setSelectedUser(-1);
                                    });
                                });
                            }}
                            onClose={() => setSelectedUser(-1)}
                        />
                    }
                    {users.map((user: User, idx: number) => {
                        const created = new Date(user.ctime);
                        const updated = new Date(user.utime);
                        return (
                            <tr key={idx}>
                                {selectedUser !== idx && (
                                    <>
                                        <td>{user.full_name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.role}</td>
                                        <td>{created.toLocaleDateString('da-DK')} {created.toLocaleTimeString().substring(0, 5)}</td>
                                        <td>{updated.toLocaleDateString('da-DK')} {updated.toLocaleTimeString().substring(0, 5)}</td>
                                        <td>
                                            <div className="buttons is-right">
                                                <a className="button" onClick={() => setSelectedUser(idx)}><IconPencil /></a>
                                                <a className="has-text-danger"><IconTrash /></a>
                                            </div>
                                        </td>
                                    </>)}

                                {selectedUser === idx &&

                                    <UserForm
                                        onSave={(user: User) => {
                                            props.cdpClient.saveUser(user, (response: any) => {
                                                props.cdpClient.getUsers((response: any) => {
                                                    setUsers(response.data || []);
                                                    setSelectedUser(-1);
                                                });
                                            });
                                        }}
                                        onClose={() => setSelectedUser(-1)}
                                        originalUser={user}
                                    />
                                }
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
