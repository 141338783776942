import { useState, ChangeEvent, ChangeEventHandler, MouseEventHandler, FC, useRef, useEffect } from 'react';
import { EventSpec } from '@/interfaces';

interface EventSpecRadioProps {
    fieldName: string;
    eventSpec: EventSpec;
    onChange: Function;
}

export const EventSpecRadio: FC<EventSpecRadioProps> = ({
    fieldName,
    eventSpec,
    onChange,
}: EventSpecRadioProps) => {
    const once = useRef(false);
    const [currentValue, setCurrentValue] = useState<number>((eventSpec as any)[fieldName]);
    const [currentEventSpec, setCurrentEventSpec] = useState<EventSpec>(eventSpec);
    // useEffect(() => {
    //     if (!once.current) {
    //         switch (fieldName) {
    //             case 'topics':
    //                 setCurrentValue(eventSpec.topics);
    //                 break;
    //             case 'strval':
    //                 setCurrentValue(eventSpec.strval);
    //                 break;
    //             case 'intval':
    //                 setCurrentValue(eventSpec.intval);
    //                 break;
    //             default:
    //                 setCurrentValue(0);
    //                 break;
    //         }
    //         once.current = true;
    //     }
    // })
    if (!eventSpec.hasOwnProperty(fieldName)) {
        return <></>;
    }

    return (
        <div className='field'>
            <label className="label" htmlFor={fieldName}>{fieldName} on event:</label>
            <div className="select">
                <select
                    name={fieldName}
                    defaultValue={currentValue}
                    onChange={(evt: any) => {
                        setCurrentValue(evt.target.value);
                        (eventSpec as any)[fieldName] = currentValue;
                        setCurrentEventSpec(eventSpec);
                        console.log('esr', currentEventSpec, fieldName, currentValue, evt.target.value);
                        onChange(fieldName, evt.target.value);
                    }}
                >
                    <option value={0}>Allowed</option>
                    <option value={1}>Required</option>
                    <option value={-1}>Disallowed</option>
                </select>
            </div>
        </div>
    );
}
