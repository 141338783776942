import { FC, useEffect } from 'react';
import { SegmentCondition, Property } from '@/interfaces';
import { useState, useRef } from 'react';

interface SegmentConditionFormProps {
    condition: SegmentCondition;
    properties: Property[];
    onSave: (condition: SegmentCondition) => void;
}

export const SegmentConditionForm: FC<SegmentConditionFormProps> = ({ condition, properties, onSave }) => {
    //const [field, setField] = useState(condition.field);
    const [op, setOp] = useState(condition.op);
    const [operatorOptions, setOperatorOptions] = useState(<></>);
    const [cond, setCond] = useState<SegmentCondition>(condition);
    const [selectedProperty, setSelectedProperty] = useState<Property>();
    const once = useRef(false);

    // Value 2 is only relevant for integer fields and non-equal operators.
    let value2 = <></>;
    if (selectedProperty?.value_type === 'int' && op === 'between') {
        value2 = <div className="field">
            <label className="label">and</label>
            <input className="input" type="number" defaultValue={condition.v2} onChange={(evt) => {
                setCond({
                    ...condition,
                    v2: evt.target.value
                });
                onSave({
                    ...condition,
                    v2: evt.target.value
                });
            }} />
        </div>;
    }

    // Change operator dropdown based on field
    const setOptions = (field: string) => {
        switch (field) {
            case 'str':
                setOperatorOptions(<select defaultValue={op} onChange={(evt) => {
                    setOp(evt.target.value);
                    setCond({
                        ...condition,
                        op: evt.target.value
                    });
                    onSave({
                        ...condition,
                        op: evt.target.value
                    });
                }}>
                    <option value="=">Equals</option>
                    <option value="!=">Not equals</option>
                    <option value="contains">Contains</option>
                    <option value="not_contains">Does not contain</option>
                </select>
                );
                break;
            case 'int':
                setOperatorOptions(<select defaultValue={op} onChange={(evt) => {
                    setOp(evt.target.value);
                    setCond({
                        ...condition,
                        op: evt.target.value
                    });
                    onSave({
                        ...condition,
                        op: evt.target.value
                    });
                }}>
                    <option value="=">Equals</option>
                    <option value="!=">Not equals</option>
                    <option value=">">Greater than</option>
                    <option value="<">Less than</option>
                    <option value="between">Between</option>
                </select>
                );
                break;
            case 'strs':
                setOperatorOptions(<select defaultValue={op} onChange={(evt) => {
                    setOp(evt.target.value);
                    setCond({
                        ...condition,
                        op: evt.target.value
                    });
                    onSave({
                        ...condition,
                        op: evt.target.value
                    });
                }}>
                    <option value="eq">All of</option>
                    <option value="not_contains">None of</option>
                    <option value="intersect">One or more of</option>
                    <option value="neq">Not equal to</option>
                </select>
                );
                break;
        }
    };
    const setPropertyAndOptions = (propertyId: number) => {
        for (let i = 0; i < properties.length; i++) {
            if (properties[i].id !== propertyId) {
                continue;
            }
            
            setOptions(properties[i].value_type);
            setSelectedProperty(properties[i]);
            break;
        }
    }
    useEffect(() => {
        if (!once.current) {
            setPropertyAndOptions(condition.pt);
            //setOptions(condition.field);
            once.current = true;
        }
    });

    // Create property dropdown
    const propertyDropdown = (<select defaultValue={condition.pt} onChange={(evt) => {
        setPropertyAndOptions(parseInt(evt.target.value + ""));
        setCond({
            ...condition,
            pt: parseInt(evt.target.value + "")
        });
        onSave(cond);
    }}>
        <option value={0}>Select a property</option>
        {properties.map((property: Property, idx: number) => {
            return <option key={idx} value={property.id}>{property.label}</option>
        })}
    </select>);

    return (
        <div>
            <div className="field">
                <label className="label">Property</label>
                <div className='level'>
                    <div className='level-left'>
                        <div className='level-item'>
                            <div className="select">
                                {propertyDropdown}
                            </div>
                        </div>
                        <div className='level-item'>
                            <span>{selectedProperty ? `(${selectedProperty?.value_type})` : ''}</span>
                        </div>
                    </div>
                </div>
            </div>
            {/*
            <div className="field">
                <label className="label">Field</label>
                <div className="select">
                    <select defaultValue={field} onChange={(evt) => {
                        setField(evt.target.value);
                        setOptions(evt.target.value);
                        setCond({
                            ...condition,
                            field: evt.target.value
                        })
                        onSave({
                            ...condition,
                            field: evt.target.value
                        });
                    }}>
                        <option value="strval">String value</option>
                        <option value="intval">Integer value</option>
                        <option value="strsval">String list/map</option>
                    </select>
                </div>
            </div> */}
            {selectedProperty?.value_type && <div className="field">
                <label className="label">Operator</label>
                <div className="select">
                    {operatorOptions}
                </div>
            </div>}
            <div className="field">
                <label className="label">Value</label>
                <div className="control">
                    <input className="input" type="text" defaultValue={condition.v1} onChange={(evt) => {
                        setCond({
                            ...condition,
                            v1: evt.target.value
                        });
                        onSave({
                            ...condition,
                            v1: evt.target.value
                        });
                    }} />
                </div>
            </div>
            {value2}
        </div>

    )
};
