import { FC, useRef, useEffect, useState } from 'react';
import { Account } from '@/interfaces';
import { AccountLimitForm, AccountTabs } from '@/forms/AccountForm';
import CdpClient from '@/client';
import LineChart from '@/components/LineChart';

interface DashboardProps {
    cdpClient: CdpClient;
}

export const AccountLimitsPage: FC<DashboardProps> = (props: DashboardProps) => {
    const client = props.cdpClient;
    const once = useRef(false);
    const [account, setAccount] = useState<Account | undefined>(undefined);
    const [stats, setStats] = useState<any[] | undefined>(undefined);
    const [eventDayStats, setEventDayStats] = useState<any[]>([]);
    useEffect(() => {
        if (!once.current) {
            client.getAccount((response: any) => {
                console.log('response', response);
                setAccount(response);
                console.log('account', account);
            });
            client.getStats('event/month', {}, (response: any) => {
                console.log('stats', response);
                setStats(response.days);
            });
            client.getStats('event', {}, (response: any) => {
                setEventDayStats(response.days);
            });
            once.current = true;
        }
    });

    return (
        <div>  
            <AccountTabs />
            <h1 className='subtitle'>Event limits</h1>
            <div className='grid'>
                <div className='cell'>
                    <div className='box'>
                        <h3 className='subtitle'>Daily event history</h3>
                        {eventDayStats && <LineChart lines={['total']} data={eventDayStats} />}
                    </div>
                </div>
                <div className='cell'>
                    <div className='box'>
                        <h3 className='subtitle'>Monthly event history</h3>
                        {stats && <LineChart lines={['total']} data={stats} />}
                    </div>
                </div>
            </div>
            {account &&
                <AccountLimitForm
                    account={account as Account}
                    onSave={(acc: Account) => {
                        setAccount(acc);
                        client.updateAccount(acc, (response: any) => {
                            setAccount(response);
                        });
                    }}
                />}
        </div>
    )
};
