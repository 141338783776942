import {FC, useState} from "react";
import { EmailAction } from "@/interfaces";

export const ActionEmailForm: FC<{emailAction: EmailAction, onSave: (emailAction: EmailAction) => void}> = ({emailAction, onSave}) => {
    const [editEmailAction, setEditEmailAction] = useState<EmailAction>(emailAction);
    const changeFunction = (key: string, evtTargetValue: string) => {
        const updatedEmailAction = {
            ...editEmailAction,
        };
        if (key === 'to') {
            updatedEmailAction.to = evtTargetValue;    
        }
        if (key === 'template') {
            updatedEmailAction.template = evtTargetValue;
        }
        setEditEmailAction(updatedEmailAction);
        onSave(updatedEmailAction);
    };
    return (
        <div>
            <div className="field">
                <label className="label">Template</label>
                <div className="control">
                    <input className="input" type="text" defaultValue={editEmailAction.template} onChange={(evt) => changeFunction('template', evt.target.value)} />
                </div>
            </div>
            <div className="field">
                <label className="label">{`Recipient (use substitution syntax for dynamic value e.g {{ p.email }} for the email property). `}</label>
                <div className="control">
                    <input className="input" defaultValue={editEmailAction.to} onChange={(evt) => changeFunction('to', evt.target.value)} />
                </div>
            </div>
        </div>
    );
}