import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan, faFloppyDisk, faPlusCircle, 
    faClose, faCog, faMagnifyingGlass, faUsers, faTable, 
    faTimeline, faIdBadge, faDashboard, faArrowCircleRight, faAt, faUser,
    faBoltLightning} from "@fortawesome/free-solid-svg-icons";

export const IconPencil = () => {
    return (
        <FontAwesomeIcon icon={faPencil} />
    )
}

export const IconTrash = () => {
    return (
        <FontAwesomeIcon icon={faTrashCan} />
    )
}

export const IconPlus = () => {
    return (
        <FontAwesomeIcon icon={faPlusCircle} />
    )
}

export const IconSave = () => {
    return (
        <FontAwesomeIcon icon={faFloppyDisk} />
    )
}

export const IconClose = () => {
    return (
        <FontAwesomeIcon icon={faClose} />
    )
}

export const IconSettings = () => {
    return (
        <FontAwesomeIcon icon={faCog} />
    )
}

export const IconSearch = () => {
    return (
        <FontAwesomeIcon icon={faMagnifyingGlass} />
    )
}
export const IconEvents = () => {
    return (
        <FontAwesomeIcon icon={faTimeline} />
    )
}
export const IconProperties = () => {
    return (
        <FontAwesomeIcon icon={faIdBadge} />
    )
}
export const IconSegments = () => {
    return (
        <FontAwesomeIcon icon={faTable} />
    )
}
export const IconUsers = () => {
    return (
        <FontAwesomeIcon icon={faUsers} />
    )
}
export const IconUser = () => {
    return (
        <FontAwesomeIcon icon={faUser} />
    )
}
export const IconDashboard = () => {
    return (
        <FontAwesomeIcon icon={faDashboard} />
    )
}
export const IconAction = () => {
    return (
        <FontAwesomeIcon icon={faBoltLightning} />
    )
}
export const IconActionWebhook = () => {
    return (
        <FontAwesomeIcon icon={faArrowCircleRight} />
    )
}
export const IconActionEmail = () => {
    return (
        <FontAwesomeIcon icon={faAt} />
    )
}
