import { useEffect, useState, useRef, FC } from 'react';
import { EventSpec } from '@/interfaces';
import { CheckboxItem } from '@/components/CheckBoxes';
import { EventCard } from '@/components/EventCard';
import { Button } from '@/components/Button';
import { CustomerProperties, CProp } from '@/components/CustomerProps';

interface EventTestPageProps {
    //eventsDefault: any;
    argoo: any;
    //topics: string[];
}



const EventTestPage: FC<EventTestPageProps> = ({ argoo }) => {
    const [cid, setCid] = useState<string>('');
    const [cprops, setCprops] = useState<CProp[]>([]);
    const [cSegs, setCsegs] = useState<string[]>([]);
    const [events, setEvents] = useState<any>([]);
    const [eventSpecs, setEventSpecs] = useState<EventSpec[]>([]);
    const once = useRef(false);
    useEffect(() => {
        if (!once.current) {
            setCid(argoo.storage.cid || '');

            argoo.cdpClient.getEventSpecs((response: any) => {
                console.log(response)
                setEventSpecs(response || []);
                once.current = true;
            });
            once.current = true;
        }
    });
    let topics: CheckboxItem[] = [
        {
            label: 'Indland',
            value: 'indland',
            isSelected: false,
        },
        {
            label: 'Politik',
            value: 'politik',
            isSelected: false,
        },
        {
            label: 'Udland',
            value: 'udland',
            isSelected: false,
        },
        {
            label: 'Erhverv',
            value: 'erhverv',
            isSelected: false,
        },
        {
            label: 'Sport',
            value: 'sport',
            isSelected: false,
        },
    ];

    const eventsDefault: any = {
        pageview: {
            str: 'Arrigoo test',
        },
        purchase: {
            str: 'dejskraber',
            int: 80,
        },
        login: {
            str: 'kristoffer@kac.dk',
        },
        nl_subscription: {
            str: 'kristoffer@kac.dk',
        },
    }
    return (
        <div>
            <div className="columns">
                <div className="column is-two-thirds">
                    <div className="grid has-5-cols">
                        {Object.values(eventSpecs).map((eventSpec, idx) => {

                            return (
                                <div className="cell" key={idx}>
                                    <EventCard
                                        eventSpec={eventSpec}
                                        evt={eventSpec.evt}
                                        str={eventsDefault[eventSpec.evt]?.str || null}
                                        int={eventsDefault[eventSpec.evt]?.int || null}
                                        topics={topics}
                                        submit={(event: string, str: string, int: number, topics: string[]) => argoo.sendEvent({ evt: event, str, int, topics })}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="column is-one-third">

                    <h2>Customer Properties</h2>
                    <Button
                        onClick={(target) => {
                            setCid(argoo.storage.cid || '');
                            if (!cid) return;
                            argoo.cdpClient.getUserData(cid, (response: any) => {
                                console.log(response);
                                setCsegs(response.s || []);
                                setCprops(response.p || []);
                            });
                        }}
                        text="Get user data"
                    />
                    <p>{cid}</p>
                    <hr />
                    <CustomerProperties
                        cprops={cprops}
                        cSegs={cSegs}
                    />
                </div>
            </div>
            <Button
                onClick={() => {
                    argoo.cdpClient.getEvents((response: any) => {
                        console.log(response)
                        setEvents(response || []);
                    })
                }}
                text="Send pageview"
            >Get events</Button>
            <table className="table">
                <thead>
                    <tr>
                        <th>Datetime</th>
                        <th>Event</th>
                        <th>Customer</th>
                        <th>String</th>
                        <th>Int</th>
                        <th>Topics</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {events.map((event: any, idx: number) => {
                        return (
                            <tr key={idx}>
                                <td>{event.ctime.substring(0, 19)}</td>
                                <td>{event.evt}</td>
                                <td>{event.cid}</td>
                                <td>{event.strval}</td>
                                <td>{event.intval}</td>
                                <td>{event.topics.join(', ')}</td>
                                <td>
                                    <Button
                                        className='is-warning is-small'
                                        onClick={(target) => {
                                            argoo.cdpClient.deleteEvent(event.id, (response: any) => {
                                                argoo.cdpClient.getEvents((response: any) => {
                                                    setEvents(response.data || []);
                                                })
                                            });
                                        }}
                                        text={`Delete event ${event.id}`}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}
export { EventTestPage }
