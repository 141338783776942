import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import logo from '@/logo.png';
import { IconAction, IconDashboard, IconEvents, IconProperties, IconSegments, IconSettings, IconUsers } from '@/components/Icons';

interface LayoutProps {
    logout: () => void;
}

export const Layout: React.FC<LayoutProps> = ({ logout }: LayoutProps) => {
    const location = useLocation();
    const path = location.pathname;
    return (
        <div data-theme="dark">
            <nav className="navbar is-dark" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="navbar-item" href="/admin">
                        <img src={logo} alt="Arrigoo" />
                    </a>
                </div>
                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <Link to="/admin/account" className="navbar-item"><IconSettings /></Link>
                            <button className="" onClick={logout}>Log out</button>
                        </div>
                    </div>
                </div>
            </nav>
            <nav className='menu-sidebar'>
                    <aside className="menu-sidebar-inner is-dark menu ar-nav" role="navigation" aria-label="main navigation">
                        <ul className="menu-list">
                            <li>
                                <Link to="/admin" className={"navbar-item " + (path=='/admin' ? 'has-text-primary' : 'has-text-light')}>
                                    <IconDashboard /> Dashboard
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/customers" className={"navbar-item " + (path=="/admin/customers" ? 'has-text-primary' : 'has-text-light')}>
                                <IconUsers /> Profiles</Link></li>
                            <li>
                                <Link to="/admin/segment-specs" className={"navbar-item " + (path=='/admin/segment-specs' ? 'has-text-primary' : 'has-text-light')}>
                                <IconSegments /> Segments</Link></li>
                            <li>
                                <Link to="/admin/event-log" className={"navbar-item " + (path=='/admin/event-log' ? 'has-text-primary' : 'has-text-light')}>
                                <IconEvents /> Events</Link>
                                {path.match(/\/event/i) && (
                                <ul>
                                    {/*<li><Link to="/event-test" className="navbar-item has-text-light">Event test</Link></li>*/}
                                    <li><Link to="/admin/event-specs" className={"navbar-item " + (path=='/admin/event-specs' ? 'has-text-primary' : 'has-text-light')}>
                                    Event Specs</Link></li>
                                </ul>)}
                            </li>
                            <li>
                                <Link to="/admin/property-specs" className={"navbar-item " + (path=='/admin/property-specs' ? 'has-text-primary' : 'has-text-light')}>
                                    <IconProperties /> Properties
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/actions" className={"navbar-item " + (path=='/admin/actions' ? 'has-text-primary' : 'has-text-light')}>
                                    <IconAction /> Actions
                                </Link>
                            </li>
                        </ul>
                    </aside>
            </nav>
            <div className="content-main">
                <div className="section">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
