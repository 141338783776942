import { useEffect, useState, useRef, FC } from 'react';
import CdpClient from '@/client';
import { Button } from '@/components/Button';
import { IconTrash, IconSearch, IconClose } from '@/components/Icons';

interface EventTestPageProps {
    cdpClient: CdpClient;
}



const EventLogPage: FC<EventTestPageProps> = ({ cdpClient }) => {
    const [events, setEvents] = useState<any>([]);
    const [selectedEvent, setSelectedEvent] = useState<number>(-1);
    const [eventSpecs, setEventSpecs] = useState<any>([]);
    const [cid, setCid] = useState<string>('');
    const [evtType, setEvt] = useState<string>('');
    const once = useRef(false);
    useEffect(() => {
        if (!once.current) {
            cdpClient.getEvents((response: any) => {
                setEvents(response || []);
            })
            cdpClient.getEventSpecs((response: any) => {
                setEventSpecs(Object.values(response));
            });
            once.current = true;
        }
    });
    return (
        <div className='box'>
            <div className='level'>
                <div className='level-left'>
                    <div className='level-item'>
                        <div className='field'>
                            <input className='input' type='text' placeholder='Customer ID' onChange={(evt) => setCid(evt.target.value)} />
                        </div>
                    </div>
                    <div className='level-item'>
                        <div className='select'>
                            <select onChange={(evt) => setEvt(evt.target.value)}>
                                <option value="">Event type</option>
                                {eventSpecs.map((spec: any, idx: number) => {
                                    return (
                                        <option key={idx} value={spec.evt}>{spec.evt}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <Button
                        className='is-primary is-medium'
                        onClick={() => {
                            if (cid === '') {
                                cdpClient.getEvents((response: any) => {
                                    setEvents(response || []);
                                }, evtType);
                                return;
                            }
                            cdpClient.getEventsForCustomer(cid, evtType, (response: any) => {
                                setEvents(response || []);
                            })
                        }}
                        
                        ><IconSearch /></Button>
                </div>
            </div>
            <table className="table is-striped">
                <thead>
                    <tr>
                        <th>Datetime</th>
                        <th>Event</th>
                        <th>Customer</th>
                        <th>String</th>
                        <th>Int</th>
                        <th>Topics</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {events.map((event: any, idx: number) => {
                        const isSelected = selectedEvent === idx;
                        return (
                            <>
                            <tr key={idx}>
                                <td>{event.ctime.substring(5, 19)}</td>
                                <td>{event.evt}</td>
                                <td>{event.cid}</td>
                                <td>{event.strval}</td>
                                <td>{event.intval}</td>
                                <td>{event.topics?.join(', ')}</td>
                                <td>
                                    <div className='buttons'>
                                        <button
                                                className='button is-small'
                                                onClick={(target) => {
                                                    const targetIndex = isSelected ? -1 : idx;
                                                    setSelectedEvent(targetIndex);
                                                }}
                                        >{!isSelected ? <IconSearch /> : <IconClose />}</button>
                                        <button
                                            className='button has-text-danger is-small'
                                            onClick={(target) => {
                                                cdpClient.deleteEvent(event.id, (response: any) => {
                                                    cdpClient.getEvents((response: any) => {
                                                        setEvents(response.data || []);
                                                    })
                                                });
                                            }}
                                            ><IconTrash /></button>
                                    </div>
                                </td>
                            </tr>
                            {selectedEvent === idx && (
                                <tr>
                                    <td colSpan={7}>
                                        <pre>{JSON.stringify(event, null, 2)}</pre>
                                    </td>
                                </tr>
                            )}
                            </>
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
}
export { EventLogPage }
