import { SegmentSpec, SegmentCondition, Property, Action, ActionTrigger } from '@/interfaces';
import { FC, useState, useEffect, useRef } from 'react';
import { SegmentConditionForm } from './SegmentConditionForm';
import { Button } from '@/components/Button';
import { Modal } from '@/components/Modal';
import { IconClose, IconPencil, IconSave, IconTrash } from '@/components/Icons';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

interface SegmentSpecFormProps {
    segment: SegmentSpec;
    properties: Property[];
    cdpClient: any;
    actions: Action[] | null;
    postSave?: () => void;
}

const closebuttonConfig = {
    title: 'Confirm to delete',
    message: 'Are you sure?',
    buttons: [
        {
            label: 'No',
            onClick: () => { }
        }
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
};

interface ActionsDropdownProps {
    actions: Action[];
    label: string;
    defaultValue: number;
    onChange: (a: Action | undefined) => void;
}

const ActionDropdown: FC<ActionsDropdownProps> = ({actions, label, defaultValue, onChange}) => {
    return (
        <div className='field'>
            <label className='label'>{label}</label>
            <div className='select'>
                <select defaultValue={defaultValue} onChange={(evt) => onChange(actions.find((action) => action.aid === parseInt(evt.target.value)))}>
                    <option value="0">Select action</option>
                    {actions.map((action, idx) => 
                        <option value={action.aid} key={idx}>{action.description}</option>
                    )}
                </select>
            </div>
        </div>
    )
};

const getDefaultActions = (actions: Action[], segment: SegmentSpec): ActionTrigger[] => {
    const defaultActions: ActionTrigger[] = [];
    let action: Action;
    for (let i = 0; i < actions.length; i++) {
        action = actions[i];
        const triggersLength = action.triggers?.length || 0;
        for (let j = 0; j < triggersLength; j++) {
            const tr = action.triggers ? action.triggers[j] : null;
            if (
                tr?.trigger_type === 'segment' && 
                tr.trigger_type_id === segment.sys_title
            ) {
                delete action.triggers;
                tr.action = action;
                defaultActions.push(tr);
                break;
            }
        }
    }
    console.log('parsed', actions, defaultActions)
    return defaultActions;
}

export const SegmentSpecForm: FC<SegmentSpecFormProps> = ({ segment, properties, cdpClient, actions, postSave }) => {
    const [segmentSpec, setSegmentSpec] = useState(segment);
    const [conditions, setConditions] = useState(segment.conditions || []);
    // const [webhooks, setWebhooks] = useState(segment.webhooks || []);
    // const [webhooksLeave, setWebhooksLeave] = useState(segment.webhooks_leave || []);
    const [isModalActive, setModalActive] = useState<boolean>(false);
    const [actionTriggers, setActionTriggers] = useState<ActionTrigger[]>([]);
    const [editTrigger, setEditTrigger] = useState<number>(-1);
    const emptyTrigger: ActionTrigger = {
        trigger_type: 'segment',
        trigger_type_id: segment.sys_title,
        conditions: {type: 'enter'}
    }
    const once = useRef(false)
    useEffect(() => {
        if (!once.current && actions) {
            const defTriggers = getDefaultActions(actions, segment)
            setActionTriggers(defTriggers);
            console.log(actions, defTriggers)
            once.current = true;
        }
    }, [actions, segment, actionTriggers]);

    const emptyCondition: SegmentCondition = {
        pt: 0,
        field: 'strval',
        op: '',
        v1: '',
        v2: ''
    };
    return (
        <div>
            <div className="field">
                <label className="label">Title</label>
                <div className="control">
                    <input className="input" type="text" defaultValue={segmentSpec.title} onChange={(evt) => {
                        setSegmentSpec({
                            ...segmentSpec,
                            title: evt.target.value
                        });
                    }} />
                </div>
            </div>
            <div className="field">
                <label className="label">System Title (lowercase only, no spaces)</label>
                <div className="control">
                    <input className="input" type="text" defaultValue={segmentSpec.sys_title} onChange={(evt) => {
                        setSegmentSpec({
                            ...segmentSpec,
                            sys_title: evt.target.value
                        });
                    }} />
                </div>
            </div>
            <div className="field">
                <label className="label">Description</label>
                <div className="control">
                    <textarea className="textarea" defaultValue={segmentSpec.description} onChange={(evt) => {
                        setSegmentSpec({
                            ...segmentSpec,
                            description: evt.target.value
                        });
                    }} />
                </div>
            </div>
            <div className='section'>
                <h3>Conditions</h3>
                {
                    conditions.map((condition: SegmentCondition, idx: number) => {
                        return <div className='box' key={idx}>
                            <SegmentConditionForm
                                condition={condition}
                                properties={properties}
                                onSave={(condition) => {
                                    console.log(condition);
                                    const newConditions = [...conditions];
                                    newConditions[idx] = condition;
                                    setConditions(newConditions);
                                    setSegmentSpec({
                                        ...segmentSpec,
                                        conditions: newConditions
                                    });
                                }} />
                        </div>
                    })
                }
                <Button text="+ Add condition" onClick={() => {
                    setConditions([...conditions, emptyCondition]);
                }} />
            </div>
            <div className='section'>
                <h3 className='subtitle'>Actions when entering or leaving the segment</h3>

                {actionTriggers.map((trigger, idx) => {
                    return (
                        <div key={idx} className='box'>
                            <div className='level'>
                                <div className='level-left'>
                                    <div className='level-item'>
                                        <span className='tag is-success'>{trigger.conditions.type}</span>&nbsp;
                                        {trigger.action?.description}&nbsp;
                                    </div>
                                </div>
                                
                                <div className='level-right'>
                                    <div className='level-item'>
                                        <button className='has-text-primary' onClick={() => {
                                            setEditTrigger(idx); setModalActive(true);}}>
                                            <IconPencil />
                                        </button>&nbsp;
                                    </div>
                                    <div className='level-item'>
                                        <button className='has-text-danger' onClick={() => {
                                            confirmAlert(
                                                {
                                                    ...closebuttonConfig,
                                                    buttons: [
                                                        closebuttonConfig.buttons[0],
                                                        {
                                                            label: 'Yes',
                                                            onClick: () => {
                                                                
                                                            }
                                                        },
                                                    ],
                                                }
                                            )
                                            
                                        }}><IconTrash /> </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                
                {actionTriggers.map((trigger, idx) => {
                    if (editTrigger !== idx) return <></>;

                    return (<Modal isActive={isModalActive} closeModal={() => setModalActive(false)}>
                        <div className='field'>
                            <label className='label'>Segment event {idx}</label>
                            <div className='select'>
                                <select defaultValue={trigger.conditions.type} onChange={(evt) => {
                                    const updTrigger: ActionTrigger = {...trigger, conditions: {type: evt.target.value}}
                                    actionTriggers[idx] = updTrigger;
                                    setActionTriggers(actionTriggers);
                                }}>
                                    <option value='enter' >Enter</option>
                                    <option value='leave' >Leave</option>
                                </select>
                            </div>
                        </div>
                        
                        {actions && <ActionDropdown
                            actions={actions || []}
                            defaultValue={trigger.action?.aid || 0}
                            label={`Select action  `}
                            onChange={(action) => {
                                const updTrigger: ActionTrigger = {...trigger, action: action}
                                actionTriggers[idx] = updTrigger;
                                setActionTriggers(actionTriggers);
                            }}
                        />}
                        <div className='buttons'>
                            <button onClick={() => {
                                trigger.id ? cdpClient.saveActionTrigger(actionTriggers[idx], () => setModalActive(false)) : cdpClient.createActionTrigger(actionTriggers[idx], () => setModalActive(false)); 
                            }} className='button is-primary'>Save &nbsp; <IconSave /></button>
                            <button onClick={() => setModalActive(false)} >Done &nbsp; <IconClose /></button>
                        </div>
                    </Modal>)
                    }
                )}
               
                <Button text="+ Add action" onClick={() => {
                    const updatedTriggers = [...actionTriggers, emptyTrigger]
                    setActionTriggers(updatedTriggers);
                    setEditTrigger(updatedTriggers.length - 1);
                    setModalActive(true)
                }} />
            </div>
            <div className='buttons'>
                <Button text="Save segment" className='is-primary' onClick={() => {
                    const properties = conditions.map((condition) => condition.pt);
                    const propertiesUnique = properties.filter((value, index, self) => self.indexOf(value) === index);
                    setSegmentSpec({
                        id: segmentSpec.id || 0,
                        ...segmentSpec,
                        properties: propertiesUnique,
                        conditions
                    });
                    const method = segmentSpec.id ? 'saveSegment' : 'createSegment';
                    console.log(segmentSpec, method);
                    cdpClient[method](segmentSpec).then((response: any) => {
                        console.log(response);
                        postSave && postSave();
                    })
                }}>Save segment &nbsp; <IconSave /></Button>
                <Link to='/admin/segment-specs' className='button'>Cancel &nbsp; <IconClose /></Link>
            </div>
        </div >
    )
};
