import { FC } from "react";
import '@material/list/dist/mdc.list.min.css'

export type CProp = {
    lb: string;
    va: any;
}

interface CPropsProps {
    cprops: CProp[];
    cSegs: string[];
}

const CustomerProperties: FC<CPropsProps> = ({
    ...props
}: CPropsProps) => {
    return (
        <table className="table has-text-left">
            <tbody>
                {props.cprops.map((prop: CProp, idx: number) => {
                    return (
                        <tr key={idx} >
                            <th  >
                                <strong>{prop.lb}:</strong>
                            </th>
                            <td >{JSON.stringify(prop.va).replace(/","/g, '", "')}</td>
                        </tr>
                    );
                })}
                <tr>
                    <th>
                        <strong>Segments:</strong>
                    </th>
                    <td>
                        <ul className="mdc-list">
                            {props.cSegs.map((seg: string, idx: number) => {
                                return (
                                    <li key={idx} className="mdc-list-item">
                                        {seg}
                                    </li>
                                );
                            })}
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>

    );
};

export { CustomerProperties };
