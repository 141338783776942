const cdp: any = {
    cid: null,
    sid: null
};
const storageKey = 'cdplight';
const GetStorage = (storage: Storage): Storage => {
    return new Proxy(cdp, {
      set: (obj, prop, value) => {
        const baseValue = storage.getItem(storageKey);
        obj = baseValue ? JSON.parse(atob(baseValue)) : {};
        if (value === null) {
          obj[prop as string] = null;
        } else {
          obj[prop as string] = value;
        }
        const savedValue = btoa(JSON.stringify(obj));
        storage.setItem(storageKey, savedValue);
        return true;
      },
      get: (obj, prop) => {
        const baseValue = storage.getItem(storageKey);
        if (!baseValue) {
            return;
        }
        obj = baseValue ? JSON.parse(atob(baseValue)) : {};
        if (!obj[prop as string]) {
          return;
        }
        return obj[prop as string];
      },
    });
  };
export default GetStorage;
