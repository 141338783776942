import { FC, ReactNode, useState } from 'react'

interface ModalProps {
    isActive: boolean;
    children: ReactNode;
    closeModal: () => void;
}

export const Modal: FC<ModalProps> = ({isActive, children, closeModal}) => {
    return (
        <div className={"modal" + (isActive ? " is-active" : "")}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <div className="box">
                    {children}
                </div>
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
        </div>
    )
}