import { EventI } from "./interfaces";

// Create a new user ID with timestamp in milliseconds and session ID based on a timestamp in seconds.
// Store both in the storage object used as a parameter.
export function userAndSession(storage: any): Partial<EventI> {
    let cid = storage.cid;
    const response: Partial<EventI> = {};
    storage.sct = storage.sct ? storage.sct + 1 : 1;
    response.fv = false;
    if (!cid) {
        storage.cid = "";
        response.fv = true;
    }
    response.cid = cid;

    let sid = storage.sid;
    response.ns = false;
    const now = Math.floor(Date.now() / 1000);
    if (!sid || (now - sid > 1800)) {
        sid = now;
        storage.sid = sid;
        response.ns = true;
        storage.sct++;
    }

    response.sid = sid;
    return response;
}

// Add base information as URL, source, and event name to the event object.
export function baseData(evt: string, strval?:string, intval?: number, src: string = 'web'): Partial<EventI> {
    if (!(strval || intval)) {
        throw new Error("The event must have a value.");
    }

    const event: Partial<EventI> = {
        evt,
        strval,
        intval,
        src,
        url: window.location.href,
    };
    return event;
}
