import { FC, useState} from 'react';
import { Link } from 'react-router-dom';
import { Action, ActionTrigger, EmailAction, Webhook } from '@/interfaces';
import { WebhookForm } from './ActionWebhookForm';
import { ActionEmailForm } from './ActionEmailForm';
import { IconSave, IconTrash, IconClose } from '@/components/Icons';

interface ActionFormProps {
    action: Action;
    triggers?: ActionTrigger[];
    onSave: (action: Action) => void;
}

export const ActionForm: FC<ActionFormProps> = ({ action, triggers, onSave }) => {
    const [editAction, setEditAction] = useState<Action>(action);

    return (
        <div className='box'>
            <div className='columns'>
                <div className='column'>
                    <div className="field">
                        <label className="label">Action type</label>
                        <div className="select">
                            <select className="select" defaultValue={editAction.action_type} onChange={(evt) => setEditAction({...editAction, action_type: evt.target.value})}>
                                <option value="webhook">Webhook</option>
                                <option value="email">Email Action</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">Description</label>
                        <div className="control">
                            <input className="input" type="text" value={editAction.description} onChange={(evt) => setEditAction({...editAction, description: evt.target.value})} />
                        </div>
                    </div>
                    {editAction.action_type === 'webhook' && <WebhookForm webhook={editAction.specs as Webhook} onSave={(webhook: Webhook) => { 
                            setEditAction({...editAction, specs: webhook});
                    }} />}
                    {editAction.action_type === 'email' && 
                        <ActionEmailForm emailAction={editAction.specs as EmailAction} onSave={(emailAction: EmailAction) => setEditAction({...editAction, specs: emailAction})} />}
                    <hr />
                    <div className="buttons">
                        <button className="button is-primary" onClick={() => onSave(editAction)}><IconSave /> &nbsp; Save</button>
                        <Link to='/admin/actions' className="button is-focus"><IconClose /> &nbsp; Cancel</Link>
                        <button className="button is-danger"><IconTrash /> &nbsp; Delete</button>
                    </div>
                </div>
                <div className='column'>
                    {action.triggers &&
                        (
                            <div>
                                <h4 className='subtitle'>Triggered on</h4>
                                {action.triggers.map((trigger: ActionTrigger, idx: number) => {
                                            return (<div key={idx}>
                                                {trigger.trigger_type} / {trigger.trigger_type_id} <br/>
                                                {trigger.trigger_type === 'segment' ? 'Segment action: ' + trigger.conditions?.type : ''}
                                            </div>)
                                        })}
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}