import { useEffect, useState, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { EventSpec, Property } from '../interfaces';
import PropertyForm from '../forms/PropertyForm';
import CdpClient from '../client';

interface PropertyPageProps {
    cdpClient: CdpClient;
}

const PropertyFormPage: React.FC<PropertyPageProps> = ({ cdpClient }) => {
    const newProperty = {
        label: 'New Property',
        value_type: 'str',
        event_requirements: [
            {
                event_type: [''],
                max_age: '',
            }
        ],
    };
    //const [selectedPropertyState, setSelectedPropertyState] = useState<number>(-2);
    const [property, setProperty] = useState<Property>();
    const [title, setTitle] = useState<string>('Edit Property');
    const [eventSpecs, setEventSpecs] = useState<EventSpec[]>([]);
    const once = useRef(false);
    const navigate = useNavigate();
    const { pid } = useParams<{ pid: string }>();
    useEffect(() => {
        if (!once.current && pid !== 'new') {
            const pidInt = parseInt(pid + '')
            cdpClient.getProperties((response: any) => {
                for (let i = 0; i < response.length; i++) {
                    if (response[i].id !== pidInt) continue;
                    setProperty(response[i]);
                    setTitle(`Edit Property ${response[i].label}`);
                }
                once.current = true;
            });
            cdpClient.getEventSpecs((response: any) => {
                setEventSpecs(Object.values(response));
            });
        }
    });
    return (
        <div className="container has-text-left">
            <Link to='/admin/property-specs'>Back to properties</Link>
            <h2 className="title">{title}</h2>
            {pid === 'new' &&
                <PropertyForm
                    property={newProperty}
                    onSave={(property: Property) => {
                        cdpClient.saveProperty(property, (response: any) => {
                            cdpClient.getProperties((response: any) => {
                                navigate('/admin/property-specs');
                            });
                        });
                    }}
                    eventSpecs={eventSpecs}
                />}


            {property && pid !== 'new' &&
                <div>
                    <PropertyForm
                        property={property}
                        eventSpecs={eventSpecs}
                        onSave={(property: Property) => {
                            cdpClient.saveProperty(property, (response: any) => {
                                cdpClient.getProperties((response: any) => {
                                    navigate('/admin/property-specs');
                                });
                            });
                        }}
                    />
                </div>
            }

        </div >
    )
}

export { PropertyFormPage };
