import { ChangeEventHandler, FC, useState } from "react";
import { EventSpec, PropCondition } from "@/interfaces";
import { ConditionFormInputField } from "./PropertyCondFormInputField";

interface PropConditionFormProps {
    onChange: (cond: PropCondition) => void;
    propertyCondition: PropCondition;
    valueType: string;
    eventSpecs: EventSpec[];
}


const PropConditionForm: FC<PropConditionFormProps> = ({
    onChange,
    propertyCondition,
    valueType,
    eventSpecs,
}: PropConditionFormProps) => {
    const [condition, setCondition] = useState<PropCondition>(propertyCondition);
    
    const fieldChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (event) => {
        const updatedCondition: any = { ...propertyCondition };
        const item = event.target.name;
        const value = event.target.value;
        updatedCondition[item] = parseInt(value);
        console.log('fc', updatedCondition);
        setCondition(updatedCondition);
        onChange((updatedCondition as PropCondition));
    }
    const fieldChangeString: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (event) => {
        const updatedCondition: any = { ...propertyCondition };
        const item = event.target.name;
        const value = event.target.value;
        updatedCondition[item] = value;
        console.log('fc', updatedCondition);
        setCondition(updatedCondition);
        onChange((updatedCondition as PropCondition));
    }
    const fieldChangeMulti: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (event) => {
        const updatedCondition: any = { ...propertyCondition };
        const item = event.target.name;
        const value = event.target.value;
        updatedCondition[item] = value.split(",").map((v: string) => v.trim());
        console.log('fcm', updatedCondition);
        setCondition(updatedCondition);
        onChange((updatedCondition as PropCondition));
    }

    return (
        <div className={`fixed-grid condition-form`}>
            <div className="">
                
                <h2 className="subtitle is-4">Event conditions</h2>
                
                <div className="field">
                    <label className="label" htmlFor="event_type">Event Types to match:</label>
                    <div className="grid">
                        {(eventSpecs.map((eventSpec, idx) => {
                            return (
                                <div className="cell" key={idx}>
                                    <div className="field">

                                        <input type="checkbox" name="event_type" value={eventSpec.evt} checked={propertyCondition.event_type.indexOf(eventSpec.evt) >= 0} onChange={(event) => {
                                            const updatedCondition: any = { ...propertyCondition };
                                            const value = event.target.value;
                                            if (event.target.checked) {
                                                updatedCondition.event_type.push(value);
                                            } else {
                                                updatedCondition.event_type = updatedCondition.event_type.filter((v: string) => v !== value && v !== "");
                                            }
                                            setCondition(updatedCondition);
                                            onChange((updatedCondition as PropCondition));
                                        }} />
                                        <label>&nbsp;{eventSpec.evt}</label>
                                    </div>
                                </div>)
                        }))}
                    </div>
                </div>
                    <ConditionFormInputField
                        fieldChange={fieldChange}
                        propertyCondition={propertyCondition}
                        label="Max age of event:"
                        fieldName="max_age"
                    />
                    <div className="field">
                        <label className="label" htmlFor="eventstrvalue">Only consider events with the following string value (comma separated for multiple):</label>
                        <input
                            type="text"
                            className="input"
                            defaultValue={propertyCondition.str_val?.join(",")}
                            name="eventstrvalue"
                            onChange={fieldChangeMulti}
                        />
                    </div>
                    <div className="field">
                        <label className="label" htmlFor="eventnumvalue">Only consider events with the following number value (comma separated for multiple):</label>
                        <input
                            type="text"
                            className="input"
                            defaultValue={propertyCondition.num_val?.join(",")}
                            name="eventnumvalue"
                            onChange={fieldChangeMulti}
                        />
                    </div>

                <div className="field">
                    <label className="label" htmlFor="eventnumvalueunder">Max. numeric value on event:</label>
                    <input
                        type="number"
                        className="input"
                        defaultValue={propertyCondition.num_val_under}
                        name="eventnumvalueunder"
                        onChange={fieldChange}
                    />
                </div>
                <div className="field">
                    <label className="label" htmlFor="eventnumvalueover">Min. numeric value on event:</label>
                    <input
                        type="number"
                        className="input"
                        defaultValue={propertyCondition.num_val_over}
                        name="eventnumvalueover"
                        onChange={fieldChange}
                    />
                </div>
                {valueType !== 'str' && (
                <>
                
                    <hr />
                    <h2 className="subtitle is-4">Processing of property value</h2>
                    
                    <div className="field">
                        <label className="label" htmlFor="aggregate">Group field:</label>
                        <div className="select">
                            <select
                                defaultValue={condition.aggregate}
                                name="aggregate"
                                onChange={fieldChangeString}
                            >
                                <option value="type">Event type</option>
                                <option value="str">String value</option>
                                <option value="topic">Topics</option>
                                <option value="session">Session</option>
                                <option value="source">Source</option>
                                <option value="referrer">Referrer</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <label className="label" htmlFor="accumulator">Accumulator:</label>
                        <div className="select">
                            <select name="accumulator" className="select" onChange={fieldChangeString} defaultValue={condition.accumulator}>
                                <option value="none">None</option>
                                <option value="sum">Sum per grouping item</option>
                                <option value="avg">Average per grouping item</option>
                                <option value="count">Count per grouping item</option>
                                <option value="max">Max value per grouping item</option>
                                <option value="single_avg_sum">Single number average of value</option>
                                <option value="single_avg_count">Single number average of count</option>
                                <option value="single_max">Single number max value</option>
                            </select>
                        </div>
                    </div>
                    
                    <ConditionFormInputField
                        fieldChange={fieldChange}
                        propertyCondition={propertyCondition}
                        label="Minimum no of occurrences:"
                        fieldName="min_occurrences"
                    />
                    <ConditionFormInputField
                        fieldChange={fieldChange}
                        propertyCondition={propertyCondition}
                        label="Maximum no of occurrences:"
                        fieldName="max_occurrences"
                    />
                    <ConditionFormInputField
                        fieldChange={fieldChange}
                        propertyCondition={propertyCondition}
                        label="Accumulated value must be above:"
                        fieldName="acc_value_over"
                    />
                    <ConditionFormInputField
                        fieldChange={fieldChange}
                        propertyCondition={propertyCondition}
                        label="Accumulated value must be below:"
                        fieldName="acc_value_under"
                    />
                </>)
                }
            </div>
        </div>
    );
};

export { PropConditionForm };
