import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Property } from '../interfaces';
import CdpClient from '../client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { IconPencil, IconTrash } from '@/components/Icons';


interface PropertyPageProps {
    cdpClient: CdpClient;
}

const PropertiesPage: React.FC<PropertyPageProps> = ({ cdpClient }) => {

    //const [selectedPropertyState, setSelectedPropertyState] = useState<number>(-2);
    const [properties, setProperties] = useState<Property[]>([]);
    const once = useRef(false);
    useEffect(() => {
        if (!once.current) {
            cdpClient.getProperties((response: any) => {
                setProperties(response || []);
                once.current = true;
            });
        }
    });
    return (
        <div className="box has-text-left">
            <h2 className="title">Properties &nbsp;
                <Link to='/admin/property-specs/new'>
                    <FontAwesomeIcon icon={faPlusCircle} /></Link>
            </h2>
            <table className='table is-fullwidth is-striped is-dark'>
                <thead>
                    <tr>
                        <th>Label</th>
                        <th>Value type</th>
                        <th>Protected</th>
                        <th>ID type</th>
                        <th>ID merge</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {properties.map((property: Property, idx: number) => {
                        return (
                            <tr key={idx}>
                                <td>{property.label}</td>
                                <td>{property.value_type}</td>
                                <td>{property.protected ? <FontAwesomeIcon icon={faCheck} /> : ''}</td>
                                <td>{property.identifier_type}</td>
                                <td>{property.identifier_merge ? <FontAwesomeIcon icon={faCheck} /> : ''}</td>
                                <td>
                                    <div className="field has-addons">
                                        <p className="control">
                                        <Link to={`/admin/property-specs/${property.id}`}>
                                                                                    <span className="button"><IconPencil /></span>
                                                                                </Link>
                                        </p>
                                        <p className="control">
                                            <button
                                                className="button has-text-danger"
                                                onClick={() => {
                                                    cdpClient.deleteProperty(property, () => {
                                                        cdpClient.getProperties((response: any) => {
                                                            setProperties(response || []);
                                                        });
                                                    });
                                                }}
                                            >
                                                <IconTrash />
                                            </button>
                                        </p>
                                        </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div >
    )
}

export { PropertiesPage };
