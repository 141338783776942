import { FC, useState } from "react";
import { CheckboxItem, Checkboxes } from "./CheckBoxes";
import { Button } from "./Button";
import { EventSpec } from "@/interfaces";

interface EventCardProps {
    eventSpec: EventSpec;
    evt: string;
    str: string;
    int: number;
    topics: CheckboxItem[];
    submit: (event: string, str: string, int: number, topics: string[]) => void;
    loading?: boolean;
}
const EventCard: FC<EventCardProps> = ({
    ...props
}: EventCardProps) => {
    const [topiclist, setTopics] = useState<CheckboxItem[]>(props.topics);
    const [strval, setStr] = useState<string>(props.str);
    const [intval, setInt] = useState<number>(props.int);
    const [loading, setLoading] = useState<boolean>(props.loading || false);

    let content = (
        setTimeout(() => {
            setLoading(false);
        }, 1500),
        <div className="card-content"> </div>
    );
    if (!loading) {
        let strField = <></>;
        if (props.eventSpec.strval >= 0) {
            strField = (<div className="field">
                <input type="text" placeholder="string value" className="input" defaultValue={strval} onChange={(e) => { setStr(e.target.value); }} />
            </div>
            );
        }
        let intField = <></>;
        if (props.eventSpec.intval >= 0) {
            intField = (
                <div className="field">
                    <input type="number" className="input" defaultValue={intval} onChange={(e) => { setInt(parseInt(e.target.value)); }} />
                </div>);
        }
        let topics = <></>;
        if (props.eventSpec.topics >= 0) {
            topics = <Checkboxes items={topiclist} onChange={(items) => { setTopics(items); }} />;
        }

        content = (
            <div className="card-content">
                {strField}
                {intField}
                {topics}
                <Button
                    className="is-primary is-small"
                    text={`Send ${props.evt}`}
                    onClick={(target) => {
                        setLoading(true);
                        const topicsToSend = topiclist.filter((item) => item.isSelected).map((item) => item.value);
                        props.submit(props.evt, strval, intval, topicsToSend);
                    }}
                />
            </div>
        );
    }
    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-header-title">{props.evt}</h4>
            </div>
            {content}
        </div>
    );
};

export { EventCard };
