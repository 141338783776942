import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bulma/css/bulma.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import arrigoo from './arrigoo';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

document.addEventListener('DOMContentLoaded', function () {
  //const host = (document.getElementsByName('body').item(0)?.attributes as any)['data-agoo-h'] || 'http://localhost:3030';
  //  arrigoo.init();
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
