import { FC, useState } from "react";
import { User } from "@/interfaces";
import { IconClose, IconSave } from "@/components/Icons";

interface UserFormProps {
    onSave: (user: User) => void;
    onClose: () => void;
    originalUser?: User;
}

const roleOptions = [
    'viewer',
    'admin',
    'account_admin',
];

const UserForm: FC<UserFormProps> = ({
    onSave,
    onClose,
    originalUser,
}: UserFormProps) => {
    const [user, setUser] = useState<User | undefined>(originalUser);

    return (
        <>
            <td>
                <div className="field">
                    <label className="label">Full name</label>
                    <input
                        className="input"
                        type="text"
                        value={user?.full_name}
                        onChange={(e) => setUser({ ...user, full_name: e.target.value } as User)}
                    />
                </div>
            </td>
            <td>
                <div className="field">
                    <label className="label">Email</label>
                    <input
                        className="input"
                        type="text"
                        value={user?.email}
                        onChange={(e) => setUser({ ...user, email: e.target.value } as User)}
                    />
                </div>
            </td>
            <td>
                <div className="field">
                    <label className="label">Phone</label>
                    <input
                        className="input"
                        type="text"
                        value={user?.phone}
                        onChange={(e) => setUser({ ...user, phone: e.target.value } as User)}
                    />
                </div>
            </td>
            <td>
                <div className="field">
                    <label className="label">Role</label>
                    <div className="select">
                        <select
                            defaultValue={user?.role}
                            onChange={(e) => setUser({ ...user, role: e.target.value } as User)}
                        >
                            {roleOptions.map((role) => (
                                <option key={role} value={role}>{role}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </td>
            <td></td>
            {/*
            <td>
                <div className="field">
                    <label className="label">Account</label>
                    <div className="select">
                        <select defaultValue={user?.account} onChange={(e) => setUser({ ...user, account: parseInt(e.target.value) } as User)}>
                            <option value={3} >Arrigoo</option>
                            <option value={4}>Noget andet</option>
                        </select>
                    </div>
                </div>
            </td>
            */}
            <td>
                <div className="buttons">
                    <a
                        className="button is-primary"
                        onClick={() => {
                            if (user) {
                                onSave(user);
                            }
                        }}
                    >
                        <IconSave />
                    </a>
                    <a
                        className="button"
                        onClick={onClose}
                    >
                        <IconClose />
                    </a>
                </div>
            </td>
        </>
    )

};

export { UserForm }
