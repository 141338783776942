import { FC } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { ChartColors } from '@/config/Charts';

interface LineChartProps {
    data: any[];
    lines: string[];
}

const LChart: FC<LineChartProps> = ({ data, lines }) => {
    return (
        <ResponsiveContainer height={300} width="100%">
            <LineChart width={600} height={300} data={data}>
                <CartesianGrid stroke="#666" />
                {lines.map((line: string, idx: number) => {
                    return (<Line key={idx} type="monotone" dataKey={line} stroke={ChartColors[idx]} />)
                })}
                <XAxis dataKey="day" />
                <YAxis />
            </LineChart>
        </ResponsiveContainer>
    );
}

export default LChart;
